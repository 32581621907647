import React from 'react';
import SKAOFooter from './SKAOFooter';
import SKAONav from './SKAONav';
import '../App.css';

import GoogleLogo from '../GoogleLogo.svg';
import MicrosoftLogo from '../MicrosoftLogo.svg';

import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody
}
from 'mdb-react-ui-kit';

const LogIn = () => {
    
    return(
        <div className="LogInContainer">
            <SKAONav />
            
            <MDBContainer fluid>

            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>

                <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
                <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                <p className="text-white-50 mb-5" style={{textAlign: 'center'}}>Please select either Google or Microsoft from the options below and proceed to sign in with your existing Google or Microsoft credentials. This is used to uniquely identify you so that your request can be processed. Once you sign in, please go to the ‘Request Access’ link that has now appeared in the top of the home screen and complete the subsequent form.</p>
                <MDBBtn className="mb-2 w-100 bg-transparent" class="btn btn-dark btn-rounded" size="lg" href="/.auth/login/google">
                    <img height="30px" width="30px" src={GoogleLogo} alt='Google'/>
                    {" "} Sign In With Google
                </MDBBtn>

                <MDBBtn className="mb-2 w-100 bg-transparent" class="btn btn-dark btn-rounded" size="lg" href="/.auth/login/aad">
                    <img height="30px" width="30px" src={MicrosoftLogo} alt='Microsoft' />
                    {" "} Sign In With Microsoft
                </MDBBtn>

                
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>


            <SKAOFooter />
        </div>
    );
    
    
}
export default LogIn;

