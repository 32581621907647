import Homepage from './components/Homepage';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SKAODocument from './components/SKAODocument';
import LogIn from './components/LogIn'
import RequestAccess from './components/SKAORequestAccess';
import SKAOCMS from './components/SKAOCMS';
import SKAORequestManager from './components/SKAORequestManager';
import axios from 'axios';
import SKAOAddDocument from './components/SKAOAddDocument';
import SKAORemoveDocument from './components/SKAORemoveDocument';
import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react'
var loading = true;

const pathname = window.location.pathname;

function App() {
  const [posts, setPosts] = useState([]);

  
  if (loading === true && pathname === "/") {
    Swal.fire('Loading Links');
    Swal.showLoading();
  }
  


  useEffect(() => {
    const loadPost = async () => {

        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);

        // Await make wait until that 
        // promise settles and return its result
        
        const response = await axios.get(
        "/api/links");

        // After fetching data stored it in posts state.

        loading = false;

        setPosts(response.data.results);

        // Closed the loading page
        // setLoading(false);
    }

    // Call the function
    loadPost(); 
  }, []);

  if (loading === false){
    Swal.close();
  }


  if (loading === false) {
    return (
      <>
      <div className="App">
        <BrowserRouter forceRefresh={true}>
        <Routes>
          <Route path="/" element={<Homepage documents={posts}/>}></Route>
          {
            (posts.map((item) =>
                // Presently we only fetch 
                // title from the API 
                <Route path={'/'+item.documentNum} element={<SKAODocument link = {item}/>}></Route>)
            )
          }
        
          <Route path="/login" element={<LogIn/>}></Route>
          <Route path="/au/requestaccess" element={<RequestAccess results={posts} />}></Route>
          <Route path="/ad/cms" element={<SKAOCMS />}></Route>
          <Route path="/ad/requestmanagement" element={<SKAORequestManager />}></Route>
          <Route path="/ad/adddocument" element={<SKAOAddDocument />}></Route>
          <Route path="/ad/removedocument" element={<SKAORemoveDocument/>}></Route>
          <Route path="/skahb-" element={<Homepage documents={posts}/>}></Route>
        </Routes>
        </BrowserRouter>
      </div>
      </>
    );
  }

}

export default App;
 