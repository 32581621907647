import 'bootstrap/dist/css/bootstrap.css';
import "react-widgets/styles.css";
import '../App.css';
import SKAONav from './SKAONav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import $ from 'jquery';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import Multiselect from "react-widgets/Multiselect";

const SKAORequestAccess = ({results}) => {
    const [result, setResult] = useState("");
    const[IDs, setIDs] = useState([]);

    var selectedDocs = [];


    useEffect(() => {
        let temp = [];
        results.map((url) => (
            temp.push(url.documentNum)
        ));
        setIDs(temp);
    }, []);

    
    


    fetch('/.auth/me')
        .then(response => response.json())
        .then(data => setResult((String(data.clientPrincipal.userDetails)) + "," + String(data.clientPrincipal.identityProvider)));
    
        // Splie result into array of strings by commas
        var resultArray = result.split(",");
    // Change p contents for id emailInput to the first item in the array
    $('#emailInput').empty();
    $('#emailInput').append(resultArray[0]);
    $('#emailInputTitle').empty();
    $('#emailInputTitle').append(resultArray[0]);
    var loginMethod= resultArray[1];

    var details = {};
    
    var emailAddress= "";
    var forename= "";
    var surname= "";
    var organisation= "";
    var typeOfAccess= "";
    var accessReason= "";
    var documentNumber= "";

    const handleSubmit = (e) => {
        
        emailAddress = resultArray[0];
        forename = $('#forenameInput').val();
        surname = $('#surnameInput').val();
        organisation =  $('#organisationInput').val();
        typeOfAccess =  "Specific Documents";
        accessReason =  $('#accessReasonInput').val();

        documentNumber = selectedDocs[0];
        
        console.log(documentNumber);

        if(documentNumber.length === 0) {
            Swal.fire({
                title: 'ERROR!',
                text: 'You Must Select a Document Number!',
                icon: 'error',
                confirmButtonText: 'OK'

            })
        }
        else{
            details = {
                "loginMethod": loginMethod,
                "email": emailAddress,
                "forename": forename,
                "surname": surname,
                "organisation": organisation,
                "typeOfAccess": typeOfAccess,
                "accessReason": accessReason,
                "documentNumber": documentNumber,
            }
            
            axios.post("/api/requests/", {body: details});
            

            Swal.fire({
                title: 'Success!',
                text: 'Your request has been submitted',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(function () {
                window.location.href = '/';
            });
                

        }

        e.preventDefault();
    }


    const setDocSelection = (value) =>{
        selectedDocs = [];
        for (var i in value) {
            selectedDocs.push(value[i]);
        }
    }


    return(
        <div id="SKAORequestAccess">
            <SKAONav />
            <div id="SKAORequestAccessHeaderContainer">

            <h1 id="SKAORequestAccessHeader">Requesting access for: <span id="emailInputTitle"></span></h1>
            </div>
            <div className='row'>
                <div className='col'></div>
                    <div className='col-12 col-md-8'>
                        <div className='row'>
                            <div className='col' >
                            <div id="SKAORequestAccessForm">

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="kindOfStand">
                    
                </Form.Group>
               <p>Requested Email Address:</p>
                <p id="emailInput" style={{color: "pink"}}></p>
                                
                <Form.Group controlId="forenameInput">
                <Form.Label>Forename:</Form.Label>
                <Form.Control type="text" placeholder="Enter your forename" required/>
                </Form.Group>
                <Form.Group controlId="surnameInput">
                <Form.Label>Surname:</Form.Label>
                <Form.Control type="text" placeholder="Enter your surname" required/>
                </Form.Group>
                <Form.Group controlId="organisationInput">
                <Form.Label>Organisation:</Form.Label>
                <Form.Control type="text" placeholder="Enter your organisation" required/>
                </Form.Group>
                <div id="docSelectDiv">
                <Form.Group controlId="documentNumberInput">
                <Form.Label>Document Number:</Form.Label>

                <Multiselect dropUp data={IDs} filter='contains' onChange={value => setDocSelection({value})}/>

                </Form.Group>
                </div>
                <Form.Group controlId="accessReasonInput">
                <Form.Label>Reason for access:</Form.Label>
                <Form.Control as="textarea" placeholder="Enter your reason for access" rows={3} required/>
                </Form.Group>
                <Button variant="success" type="submit" id="SKAORequestAccessSubmit" className="bg-transparent">
                    Submit
                </Button>
            </Form>
            </div>
                            </div>
                        </div>
                    </div>
                <div className='col'></div>
            </div>
            

        </div>
    );
}

export default SKAORequestAccess;