
import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import SKAONav from './SKAONav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, {useState} from 'react';
// Import MDBFile
import { MDBFile } from 'mdb-react-ui-kit';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
const { BlockBlobClient, AnonymousCredential } = require("@azure/storage-blob");

const SKAOAddDocument = () => {
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfFileError, setPdfFileError] = useState('');
    const fileTypes = ['application/pdf', 'video/mp4'];
    const [item, setItem] = useState({ restrictedInput: "", another: "another" });
    const [currentDocName, setCurrentDocName] = useState('');
    const { restrictedInput } = item;

    var details = {};
    
    var documentNumber = "";
    var documentTitle = "";
    var copyrightHolder = "";

    const handleChange = e => {
        e.persist();
    
        setItem(prevState => ({
          ...prevState,
          restrictedInput: e.target.value
        }));
    };

    //onchange event
    const handlePdfFileChange = (e) => {
        let selectedFile = e.target.files[0];
        setCurrentDocName(selectedFile.name);
        if (selectedFile) {
            if (fileTypes.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onloadend = (e) => {
                    setPdfFile(e.target.result);
                    setPdfFileError('');
                }
            } else {
                setPdfFile(null);
                setPdfFileError('Please Select a Valid PDF or MP4 File');
            }
        } else {
            console.log('select your file');
        }
    }

    //form submit
    const handlePdfFileSubmit = async (e) => {
        e.preventDefault();
        if (restrictedInput === "") {
            Swal.fire({
                title: 'ERROR!',
                text: 'You Must Select A Restriction Type!',
                icon: 'error',
                confirmButtonText: 'OK'
            });

            e.preventDefault();
            e.stopPropagation();
        } else {
            documentNumber = $('#documentNumberInput').val();
            copyrightHolder = $('#copyrightHolder').val();
            documentTitle = $('#docTitleInput').val();
            

            details = {
                "documentNum": documentNumber,
                "copyrightHolder": copyrightHolder,
                "documentTitle": documentTitle,
                "restrictedAccess": restrictedInput,
                "fileExtension": currentDocName.endsWith(".pdf") ? ".pdf" : ".mp4"
            }

            const response = await axios.post("/api/storagemanager/", { body: details });
            
            const sasKey = response.data.sasKey;
            const url = response.data.url;

            var containerName = "";
            if (restrictedInput === "Y") {
                containerName = "private-archive";
            } else {
                containerName = "public-archive";
            }

            var blobName = currentDocName.endsWith(".pdf") ? `${documentNumber}.pdf` : `${documentNumber}.mp4`;
            var login = `${url}/${containerName}/${blobName}?${sasKey}`;

            var blockBlobClient = new BlockBlobClient(login, new AnonymousCredential());

            Swal.fire('Uploading Document');
            Swal.showLoading();

            var blobContentType = currentDocName.endsWith(".pdf") ? "application/pdf" : "video/mp4";

            await blockBlobClient.uploadData(pdfFile, {
                blobHTTPHeaders: {
                    blobContentType: blobContentType,
                },
            });

            Swal.close();

            Swal.fire({
                title: 'Success!',
                text: 'Your Document has been submitted',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(function () {
                window.location.href = '/ad/cms';
            });
        }
    }


    
    return(
        <div id="SKAORequestAccess">
            <SKAONav />
            <div id="SKAORequestAccessHeaderContainer">

            <h1 id="SKAORequestAccessHeader">Add SKAO Document</h1>
            </div>
            <div className='row'>
                <div className='col'></div>
                    <div className='col-12 col-md-8'>
                        <div className='row'>
                            <div className='col' >
                            <div id="SKAORequestAccessForm">

            <Form onSubmit={handlePdfFileSubmit}>

            
                <div className="m-2">
                <MDBFile label='Upload Document' id='customFile' onChange={handlePdfFileChange}/>
                    {pdfFileError&&<div className='error-msg'>{pdfFileError}</div>}
                </div>

                <Form.Group className="m-2" controlId="documentNumberInput">
                    <Form.Label>Document Number:</Form.Label>
                    <Form.Control type="text" placeholder="SKAHB-XX" required/>
                </Form.Group>

                <Form.Group className="m-2" controlId="copyrightHolder">
                <Form.Label>Copyright Holder:</Form.Label>
                <Form.Control type="text" placeholder="Copyright Holder" required/>
                </Form.Group>
                <Form.Group className="m-2" controlId="docTitleInput">
                <Form.Label>Document Title:</Form.Label>
                <Form.Control type="text" placeholder="Enter Document Title" required/>
                </Form.Group>
                <Form.Group required className="m-2">
                <Form.Label>Type of access:</Form.Label>
                <div className="check-container">
                    <Form.Check
                    type="radio"
                    value="N"
                    aria-label="radio 1"
                    id="unrestrictedCheck"
                    label="Unrestricted"
                    onChange={handleChange}
                    checked={restrictedInput === "N"}
                    />
                    <Form.Check
                    type="radio"
                    value="Y"
                    aria-label="radio 2"
                    id="restrictedCheck"
                    label="Restricted"
                    onChange={handleChange}
                    checked={restrictedInput === "Y"}
                    />
                    
                        
                    </div>
                </Form.Group>
               
                <Button variant="primary" type="submit" id="SKAORequestAccessSubmit" className="m-2 bg-transparent">
                Submit
                </Button>
            </Form>
            </div>
                            </div>
                        </div>
                    </div>
                <div className='col'></div>
            </div>
            

        </div>
    );
}

export default SKAOAddDocument;