import React from "react"

const SKAOFooter = () => {

    return (
        <div>
            
        </div>
    );
}
export default SKAOFooter;