import {Table} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import $ from 'jquery';
import Swal from 'sweetalert2';
import axios from 'axios';

const SKAOTable = ({results}) => {
  var width = window.innerWidth;
  // If the width is less than 768px, hide the table
  // If width < 610, jquery hide url-col-hide
  if (width < 610) {
    $('.url-col-hide').hide();
  } else {
    $('.url-col-hide').show();
  }
  window.addEventListener('resize', function(event) {
    // Get the width of the viewport
    var width = window.innerWidth;
    // If the width is less than 768px, hide the table
    // If width < 610, jquery hide url-col-hide
    if (width < 610) {
      $('.url-col-hide').hide();
    } else {
      $('.url-col-hide').show();
    }
  }, true);

  for(var i = 0; i < results.length; i++) {
    if(!results[i].hasOwnProperty('copyrightHolder')) {
      results[i].copyrightHolder = "No Copyright Holder on Record";
    } else if(results[i].copyrightHolder === ""){
      results[i].copyrightHolder = "No Copyright Holder on Record"
    }
  }

  async function handleURLClick(event, documentNum) {

    event.preventDefault();

    const response = await axios.post(
    "/api/links", {body: documentNum});

    if (response["data"]["revoke"]) {
        Swal.fire({
            title: 'WARNING!',
            text: 'Access to this reference material is restricted for scholarly research purposes only. To request access, click OK below and then click the Sign In button in the top right corner of the home screen.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
    } else {
      window.open('/' + documentNum, '_blank');
    }
  }
  
  return (
    
    <div className="links-container" style={{display:"none"}}>
      
      <Table bordered hover className='document-table'>
        <thead className="urlTableHeader">
          <tr>
            <td style={{display: 'none'}}>Prefix</td>
            <td style={{color: 'white'}}>Document Name</td>
            <td className="url-col-hide" style={{color: 'white'}}>Copyright Holder</td>
            <td className="url-col-hide" style={{color: 'white'}}>URL</td>
          </tr>
        </thead>
        <tbody className="urlTable">
        {results.map((url, index) => (
        <tr key={index} className="documentRow">
        <td style={{display: 'none'}}>{url.documentNum}</td>
        <td style={{color: 'white', cursor: 'pointer', textDecoration: 'underline' }} onClick={(event) => handleURLClick(event, url.documentNum)} >{url.documentTitle}</td>
        <td className="copyright-holder-cell url-col-hide" style={{color: 'white'}}>
          <div className="scrollable-cell">{url.copyrightHolder}</div>
        </td>
        <td className="url-col-hide pink-url" style={{ cursor: 'pointer', textDecoration: 'underline', color: 'white' }} onClick={(event) => handleURLClick(event, url.documentNum)}>{url.shortenedURL}</td>
      </tr>
        ))}
        </tbody>
      </Table>
    </div>
  );
  
}

export default SKAOTable;