import React, { useState, useEffect } from 'react'
import SKAONav from './SKAONav';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css';

const SKAODocument = ({link}) => {
    const [result, setResult] = useState("");
    const [isMp4, setIsMp4] = useState(false);
    useEffect(() => {
        const loadResult = async () => {
  
            // Till the data is fetch using API 
            // the Loading page will show.
            // setLoading(true);
  
            // Await make wait until that 
            // promise settles and return its result
            const response = await axios.post(
            "/api/links", {body: link.documentNum});

            if (response["data"]["revoke"]) {
                Swal.fire({
                    title: 'WARNING!',
                    text: 'Access to this reference material is restricted for scholarly research purposes only. To request access, click OK below and then click the Sign In button in the top right corner of the home screen.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(function () {
                    window.location.href = '/';
                });
            }
  
            // After fetching data stored it in posts state.
            console.log(response.data.token);
            const token = (response.data.token);
        
            setResult(token);
            if (token.endsWith(".mp4")) {
                setIsMp4(true);
            }
            else {
                setIsMp4(false);
            }
        }
        
        // Call the function
        loadResult();
    }, []);

    
    return(
        <div className="home-container">
            <SKAONav />
            {isMp4 ? (
                <div className="video-container">
                    <ReactPlayer
                        url={result} 
                        controls={true} 
                        playing={true} 
                        loop={true} 
                        muted={true} 
                        width='100%' 
                        height='100%' 
                        id="skaoMP4"
                    />
                </div>
               
            ) : (
                <div className="pdfIframe" align="center">
                    <iframe 
                        src={result} 
                        width="800" 
                        height="800" 
                        frameBorder="0" 
                        title="SKAO Document" 
                        id="skaoPDF" 
                        allowFullScreen 
                    />
                </div>
            )}
            </div>
    );
}
export default SKAODocument;

