
import 'bootstrap/dist/css/bootstrap.css';
import '../App.css';
import SKAONav from './SKAONav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, {useState} from 'react';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
// useState for links




const SKAORemoveDocument = () => {
    const [posts, setPosts] = useState([]);
    var apiResponse = [];

    const deleteButtonClicked = () => {
        // Use jQuery to get the value of the input field
        var documentNumber = $('#documentNumberInput').val();
        console.log(documentNumber);
        // Post request to /api/requestmanager with the values remove and documentNumber
        axios.post('/api/storagemanager', {
            action: 'remove',
            documentNumber: documentNumber
        })
        .then(res => {
            console.log(res);
            console.log(res.data);
            Swal.fire({
                title: 'Success!',
                text: 'Document has been removed',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(function () {
                window.location.href = '/ad/cms';
            });
        });
    }

    // useEffect to query /api/links
    useEffect(() => {
        axios.get('/api/links')
        .then(res => {
          apiResponse = res.data.results;
          console.log(apiResponse);
          setPosts(apiResponse);
        })
        .catch(err => {
          console.log(err);
        })
      }, []);
    console.log(posts)
    //  Print every value in newLinks

    return(
        <div id="SKAORequestAccess">
            <SKAONav />
            <div id="SKAORequestAccessHeaderContainer">

            <h1 id="SKAORequestAccessHeader">Remove SKAO Document</h1>
            </div>
            <div className='row'>
                <div className='col'></div>
                    <div className='col-12 col-md-8'>
                        <div className='row'>
                            <div className='col' >
                            <div id="SKAORequestAccessForm">

            <Form>
                <Form.Group className="m-2" controlId="documentNumberInput">
                    <Form.Label>Document Number:</Form.Label>
                    <Form.Select aria-label="Default select example">
                    


                    <option>Open this select menu</option>
                    {posts.map((url, index) => (
                        <option key={index} value={url.documentNum}>{url.documentNum}</option>
        
      ))}
                    </Form.Select>
                </Form.Group>
                
               
                <Button variant="primary" id="SKAORequestAccessSubmit" className="m-2 bg-transparent" onClick = {deleteButtonClicked}>
                Submit
                </Button>
            </Form>
            </div>
                            </div>
                        </div>
                    </div>
                <div className='col'></div>
            </div>
            

        </div>
    );
}

export default SKAORemoveDocument;