import SKAOFooter from './SKAOFooter';
import SKAONav from './SKAONav';
import SKAOTable from './SKAOTable';
import SKAOSearch from "./SKAOSearch";
import '../App.css';


const Homepage = ({documents}) => {
    console.log("Homepage loaded");
    return(
        <div className="home-container">
            <div id="toAddNavPadding">
                <SKAONav/>
            </div>
            <SKAOSearch />
            <SKAOTable results={documents}/>
            
            <SKAOFooter />           
        </div>
    );
    
    
}
export default Homepage;

