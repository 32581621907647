import SKAOFooter from './SKAOFooter';
import SKAONav from './SKAONav';
import "../App.css";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody
}
from 'mdb-react-ui-kit';

function SKAOCMS() {
    return(
        <div>
            <SKAONav/>
            <MDBContainer fluid>

            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>

                <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
                <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                <h2 className="fw-bold mb-2 text-uppercase">Admin Controls</h2>
                <MDBBtn className="mb-2 w-100" class="btn btn-dark btn-rounded" size="lg" href="/ad/RequestManagement">
                  Manage Access Requests
                </MDBBtn>

                <MDBBtn className="mb-2 w-100" class="btn btn-dark btn-rounded" size="lg" href="/ad/AddDocument">
                    Add Documents
                </MDBBtn>

                <MDBBtn className="mb-2 w-100" class="btn btn-dark btn-rounded" size="lg" href="/ad/RemoveDocument">
                    Remove Documents
                </MDBBtn>
                        
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>
            <SKAOFooter />    
        </div>
    );
}

export default SKAOCMS;