import SKAONav from './SKAONav';
import {Table, Button} from 'react-bootstrap';
import "../App.css";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
// Import sweetalert2
import Swal from 'sweetalert2';
import $ from 'jquery';

function SKAORequestManager() {
    const [approvals, setApprovals] = useState([]);
    

    useEffect(() => {
        const fetchData = async () => {
            await fetch('/api/requestapproval').then((response) => response.json()).then((actualData) => {
                setApprovals(actualData.results);
            })
            .catch((err) => {
                
                console.log(err.message);
            });
        }
        
        fetchData()
        
    }, []);

    const denyRequest = (objectID, index) => {
        axios.post("/api/requestdenial/", {body: objectID});
        // Swal to show denied request
        Swal.fire({
            title: 'Denied',
            text: 'You have denied a request',
            icon: 'error',
            confirmButtonText: 'OK',
            });
        
        var rowID = '.approvalRow'+index;
        $(rowID).remove();

    }

    const approveRequest = (objectID, index) => {
        axios.post("/api/requestapproval", {body: objectID});
        // Swal to show approved request
        Swal.fire({
            title: 'Approved',
            text: 'You have approved a request',
            icon: 'success',
            confirmButtonText: 'OK',
            });
        
        var rowID = '.approvalRow'+index;
        $(rowID).remove();
    }

    
    return(
        <div>
            <SKAONav/>
            <div className="links-container">
                <Table bordered hover className='document-table'>
                    <thead className="urlTableHeader">
                    <tr>
                        <td style={{color: 'white'}}>Email</td>
                        <td style={{color: 'white'}}>Forename</td>
                        <td style={{color: 'white'}}>Surname</td>
                        <td style={{color: 'white'}}>Organisation</td>
                        <td style={{color: 'white'}}>Type Of Access</td>
                        <td style={{color: 'white'}}>Reason For Access</td>
                        <td style={{color: 'white'}}>Document ID</td>
                        <td style={{color: 'white'}}>Approve</td>
                        <td style={{color: 'white'}}>Deny</td>
                        
                    </tr>
                    </thead>
                    <tbody className="urlTable">
                    {approvals.map((entry, index) => (
                    <tr key={index} className={"approvalRow"+index}>
                        <td style={{color: 'white'}} className="scrollable-cell" >{entry.email}</td>
                        <td style={{color: 'white'}} className="scrollable-cell">{entry.forename}</td>
                        <td style={{color: 'white'}} className="scrollable-cell">{entry.surname}</td>
                        <td style={{color: 'white'}} className="scrollable-cell">{entry.organisation}</td>
                        <td style={{color: 'white'}} className="scrollable-cell">{entry.typeOfAccess}</td>
                        <td style={{color: 'white'}} className="scrollable-cell">{entry.accessReason}</td>
                        <td style={{color: 'white'}} className="scrollable-cell">{entry.documentNumber}</td>
                        <td><Button variant="success" className="searchItem bg-transparent" value={[entry._id, index]} onClick={() => approveRequest(entry._id, index)}>Approve</Button> </td>
                        <td><Button variant="danger" className="searchItem bg-transparent" value={[entry._id, index]} onClick={() => denyRequest(entry._id, index)}>Deny</Button> </td>
                    </tr>
                    ))}
                    
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default SKAORequestManager;