import {Button, Nav, Navbar} from 'react-bootstrap';
import { useState, useEffect } from "react";
import '../App.css';
import SKAOLogo from '../SKAOLogo.svg';
import Container from 'react-bootstrap/Container';
import axios from 'axios';

const SKAONav = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [adminStatus, setAdminStatus] = useState(false);

  useEffect(() => {
    const checkLogin = async () => {
      const response = await axios.get("/.auth/me");

      let roles = [];
    
      if (response.data.clientPrincipal === null) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }

      if (response.data.clientPrincipal != null) {
        roles = response.data.clientPrincipal.userRoles;
        if (roles.indexOf("admin") > -1) {
          setAdminStatus(true);
        }
      }

      
      
    }
    checkLogin(); 
  }, []);
  

  return (
       <Navbar expand="lg" id="skaoNavID" className='navbar-dark ms-auto'>
      <Container fluid>
      <Navbar.Brand href="/"><img alt="SKAOLogo.svg" src={SKAOLogo}></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/" className='white-class'>Homepage</Nav.Link>
            <Nav.Link href="/au/requestaccess" className='white-class'>Request Access</Nav.Link>
            {adminStatus ? <Nav.Link href="/ad/cms" className='white-class'>Admin Controls</Nav.Link> : <></>}
          </Nav>

          
          <span className='white-class'>Documents held by this archive may be subject to copyright controls. For site guidance and copyright information, click <a href="https://www.skao.int/en/487/copyright">Here</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>

          {loginStatus ? <Button className="d-flex me-2 bg-transparent pl-3" variant="primary" href="/logout">Sign Out</Button> : <Button className="d-flex me-2 bg-transparent pl-3" variant="primary" href="/logIn">Sign In</Button>}

        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default SKAONav;