import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import '../App.css';
import Swal from 'sweetalert2';


// This function will now create a regex to match the document number pattern at the start of the search query
function createDocumentNumberRegex() {
    return new RegExp(/^[a-zA-Z0-9]+-\d+/);
}

function SKAOSearch() {
    let initialSearch = false;

    $('.links-container').hide();
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchClicked(e);
        }
    }

    let documentArray = [];
    $('.documentRow').each(function(){
        documentArray.push($( this ).text());
    });

    const searchClicked = (e, paramTest) =>{
        let searched = $('#formDocumentSearch').val();
        if(searched === ""){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a search term!',
            });
            return;
        }
        else{
            $('#toAddNavPadding').addClass('nav-padding');
            if(!initialSearch){
                $('#skao-search-whole-container').animate({
                    height: '-=40rem'
                }, "slow");
                initialSearch = true;
            }
            $('.links-container').fadeIn(1000);
            $('.siteFooter').fadeOut(1000);
              
            $(".documentRow").each(function(){
                $(this).hide();
            });
            
            const regex = createDocumentNumberRegex();
            let match = regex.exec(searched.toLowerCase());
            let searchPrefix = match ? match[0] : "";

            $(".documentRow").each(function() {
                let documentNumber = $(this).find('td:first').text().trim().toLowerCase();

                // Exact match up to the length of the searchPrefix
                if (documentNumber.startsWith(searchPrefix) && documentNumber.length === searchPrefix.length) {
                    $(this).show();
                } else {
                    $(this).hide(); // Hide the element if it does not match
                }
            });
        }
        e.preventDefault();
    }
    
    return(
        <div id="skao-search-whole-container" className='skao-search-middle'>
            <div className="skao-search-container">
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-8 col-md-6'>
                        <Form>
                            <Form.Group className="mb-3" controlId="formDocumentSearch" onKeyPress={handleKeyPress} >
                                <Form.Control placeholder="Search for Documents..." />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className='col'></div>
                </div>
                <div className='row'>
                    <div className='col'></div>
                        <div className='col-8 col-md-6'>
                            <div className='row'>
                                <div className='col' >
                                    <Button variant="primary" className="searchItem submitButton bg-transparent" onClick={searchClicked} >
                                        Submit
                                    </Button> 
                                </div>
                                <div className='col ' id="searchID">
                                </div>
                            </div>
                        </div>
                    <div className='col'></div>
                </div>
            </div>
        </div>
    )
}

export default SKAOSearch;